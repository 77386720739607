import React, { useEffect , useState } from 'react';
import './App.css'; // Import the CSS file
import M from 'materialize-css/dist/js/materialize.min';
import '@fortawesome/fontawesome-free/css/all.min.css';


// Import Materialize JavaScript for dropdowns
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css/dist/js/materialize.min';

const App = () => {
  const [showIcons, setShowIcons] = useState(false);
  useEffect(() => {
    // Initialize Materialize dropdowns
    const elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, {});

    // Remove the splash overlay after animation
    const splashOverlay = document.getElementById('splash-overlay');
    splashOverlay.addEventListener('animationend', () => {
      splashOverlay.style.display = 'none';
      setShowIcons(true); 
    });
  }, []);

  return (
    <div className="welcome">
      
      <span id="splash-overlay" class="splash"></span>
      <span id="welcome" class="z-depth-4"></span>

      <header class="navbar-fixed">
      
      </header>
      <header class="navbar-fixed">
      
      </header>
      <header class="navbar-fixed">
      
      </header>
      <header class="navbar-fixed">
      
      </header>
      <main class="valign-wrapper">
        <span class="container grey-text text-lighten-1">
          <p class="flow-text">Coming Soon</p>
          <h1 class="title grey-text text-lighten-3">ImgnArab</h1>
          <blockquote class="flow-text">هنا بيتك وملاذك أينما كُنت، ومهما كانت الصعوبات التي تمر بها ستظل تخيّل ملجئًا آمنًا لك، هنا الطفل الذي تركته خلفك وأنت تَمُر بصعوبات الحياة، ذلك الطفل ذو العقل الفضولي، الحُر، المُبدع، المُكتشِف، هنا حيث يُصبح السحر حقيقة.</blockquote>
        
          {/* Social Media Links - Hidden until splash animation ends */}
          {showIcons && (
            <div className="social-icons">
              <a href="https://www.facebook.com/imgnarab" className="grey-text text-lighten-3">
                <i className="fab fa-facebook fa-2x"></i>
              </a>
              <a href="https://www.tiktok.com/@imgnarab" className="grey-text text-lighten-3">
                <i className="fab fa-tiktok fa-2x"></i>
              </a>
              <a href="https://www.instagram.com/imgnarab/" className="grey-text text-lighten-3">
                <i className="fab fa-instagram fa-2x"></i>
              </a>
              <a href="https://www.youtube.com/@imgnarab" className="grey-text text-lighten-3">
                <i className="fab fa-youtube fa-2x"></i>
              </a>
              <a href="https://www.twitch.tv/imgnarab" className="grey-text text-lighten-3">
    <i className="fab fa-twitch fa-2x"></i>
  </a>
            </div>
          )}
         
        </span>
      </main>

      

      <div id="message" className="modal modal-fixed-footer">
        <div className="modal-content">
          <h4>Contact</h4>
          <p>Coming soon...</p>
        </div>
        <div className="modal-footer">
          <a href="#!" className="modal-action modal-close waves-effect btn-flat">Close</a>
        </div>
      </div>

      
    </div>
  );
};

export default App;
